<template>
  <div class="ma-3">
    <v-toolbar class="mb-3">
      <v-toolbar-title>Capstone Carrel Signups</v-toolbar-title>
    </v-toolbar>
    <v-row>
      <v-col v-if="selectedCarrel" :cols="12" lg="10" offset-lg="1" xl="8" offset-xl="2">
        <v-alert type="info" class="mb-0">
          <template v-if="selectedCarrelTerm !== term" v-slot:append>
            <capstone-carrel-dialog v-if="eligibleForSecondCarrel" :term="term" @selected="selectCarrel"></capstone-carrel-dialog>
            <v-chip v-else label outlined large>Not eligible for second</v-chip>
          </template>
          <template v-else-if="cancelable" v-slot:append>
            <v-dialog v-model="cancelCarrelDialog" width="400">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" outlined class="ml-3">Cancel</v-btn>
              </template>
              <v-card>
                <v-card-title>Cancel Carrel Reservation</v-card-title>
                <v-card-text>Are you sure you want to return this carrel? This will allow another user to select this carrel and for you to select a different carrel.</v-card-text>
                <v-card-actions>
                  <v-btn color="success" text @click="cancelCarrelDialog = false">Keep Reservation</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="error" text @click="cancelCarrel">Cancel Reservation</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <span v-if="selectedCarrelTerm === term">You are currently signed up for carrel {{ selectedCarrel }} for this term.</span>
          <span v-else>You were signed up for carrel {{ selectedCarrel }} for last term{{ eligibleForSecondCarrel ? ', but as a double-major you are eligible to reserve a carrel for a second term.' : '' }}.</span>
        </v-alert>
      </v-col>
      <v-col md="6" class="d-none d-md-flex">
        <v-img :src="imageSource" :height="imageHeight"></v-img>
      </v-col>
      <v-col>
        <v-card v-if="!hasCapstone">
          <v-card-text>It looks like you are not signed up for a Capstone course for the current term. The Capstone Carrels are only available to those who are currently taking a Capstone course, and are only available for one term (unless you are a double-major). If you believe this is in error, please contact Technology Services by emailing techsupport@covenant.edu.</v-card-text>
        </v-card>
        <v-card v-else-if="isOpen">
          <v-card-text v-html="text"></v-card-text>
          <v-card-actions v-if="cancelable && term && (!selectedCarrel || eligibleForSecondCarrel)">
            <capstone-carrel-dialog :term="term" @selected="selectCarrel"></capstone-carrel-dialog>
          </v-card-actions>
          <v-card-text v-if="term === ''">
            <p>There is no term available for carrel sign-ups at this time.</p>
          </v-card-text>
        </v-card>
        <v-card v-else>
          <v-card-title>Carrel Signup Not Avaiable</v-card-title>
          <v-card-text>
            <p>Capstone Carrel signups are not yet available. Please try again at a later date.</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<style scoped>
  .v-alert >>> .v-alert__icon {
    margin-top: 5px;
  }
</style>
<script>
import { onMounted, ref, computed } from '@vue/composition-api'
import { courseList } from '@/components/student/capstone'

export default {
  components: {
    CapstoneCarrelDialog: () => import('@/components/library/carrel/selectCarrelDialog')
  },
  setup (props, { root }) {
    const service = root.$feathers.service('library/carrel')
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)

    const num = (Math.floor(Math.random() * 100) % 33)
    const imageSource = require('@/assets/backgrounds/background' + (num < 10 ? '0' : '') + num + '.jpg')
    const imageHeight = ref(root.$vuetify.breakpoint.name === 'xs' || root.$vuetify.breakpoint.name === 'sm' ? 300 : 600)

    const term = ref('')
    const capstoneCourse = ref('')
    const hasCapstone = computed(() => capstoneCourse.value !== '')
    const text = ref('')
    const isOpen = ref(false)
    const selectedCarrel = ref(null)
    const selectedCarrelId = ref(null)
    const selectedCarrelTerm = ref(null)
    const eligibleForSecondCarrel = ref(false)

    onMounted(async () => {
      const endDate = new Date()
      endDate.setDate(endDate.getDate() - 10)
      const { data: termData } = await root.$feathers.service('system/term').find({ query: { type: 'Traditional', end: { $gte: endDate }, $sort: { term: 1 }, $limit: 1, $select: ['term'] } })
      if (termData.length > 0) {
        term.value = termData[0].term
      }

      const terms = [term.value]
      if (term.value.substring(4, 6) === '05') terms.push((parseInt(term.value) - 45) + '')
      // Check for whether they are registered for a capstone course
      let { data: courseData } = await root.$feathers.service('calendar/classes').find({ query: { term: { $in: terms }, 'students.pidm': user.value.pidm, $limit: 50 } })
      console.log(courseData)
      for (const { crn, title } of courseData) {
        const code = title.substring(0, 6)
        console.log(code, crn)
        if (courseList.filter((val) => val === code).length > 0) {
          capstoneCourse.value = crn
          break
        }
      }

      const { data: carrelTermData } = await root.$feathers.service('library/carrel-term').find({ query: { term: term.value } })
      if (carrelTermData.length > 0) {
        text.value = carrelTermData[0].text
        const open = new Date(carrelTermData[0].open)
        const cutoff = new Date(carrelTermData[0].cutoff)
        const now = new Date()
        cancelable.value = now < cutoff
        isOpen.value = open < now

        const { total, data } = await service.find({ query: { term: term.value, 'slots.directoryId': user.value.directoryId } })
        if (total > 0) {
          selectedCarrel.value = data[0].carrel
          selectedCarrelId.value = data[0]._id
          selectedCarrelTerm.value = data[0].term
        } else {
          const { data } = await service.find({ query: { term: { $in: terms }, 'slots.directoryId': user.value.directoryId } })
          if (data.length > 0) {
            selectedCarrel.value = data[0].carrel
            selectedCarrelId.value = data[0]._id
            selectedCarrelTerm.value = data[0].term
            // Check to see if they are a double-major and eligible for a second carrel
            const { data: stuData } = await root.$feathers.service('student/term-detail').find({ query: { pidm: user.value.pidm, term: term.value } })
            if (stuData.length > 0 && stuData[0].academics.major.length > 1) eligibleForSecondCarrel.value = true
          }
        }
      }
    })

    async function selectCarrel (carrel) {
      const { data } = await service.find({ query: { term: term.value, carrel } })
      selectedCarrel.value = carrel
      selectedCarrelId.value = data[0]._id || null
      selectedCarrelTerm.value = term.value
    }

    const cancelable = ref(true)
    const cancelCarrelDialog = ref(false)
    async function cancelCarrel () {
      const { slots } = await service.get(selectedCarrelId.value)
      for (let i = 0; i < slots.length; i++) {
        if (slots[i].directoryId === user.value.directoryId) {
          slots.splice(i, 1, { available: true })
          await service.patch(selectedCarrelId.value, { slots })
          selectedCarrel.value = null
          selectedCarrelId.value = null
          selectedCarrelTerm.value = null
          cancelCarrelDialog.value = false
          return
        }
      }
    }

    return {
      user,
      imageSource,
      imageHeight,
      term,
      capstoneCourse,
      hasCapstone,
      text,
      isOpen,
      selectedCarrel,
      selectedCarrelId,
      selectedCarrelTerm,
      eligibleForSecondCarrel,
      selectCarrel,
      cancelable,
      cancelCarrelDialog,
      cancelCarrel
    }
  }
}
</script>
